@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,400;0,500;0,600;0,800;0,900;1,500;1,600;1,700&family=Quicksand:wght@500;600&display=swap");

/* ==== Base ===== */
* {
padding: 0;
margin: 0;
box-sizing: border-box;
font-family: "Quicksand", sans-serif;
font-size: 4rem;
}


body {
line-height: 1.3;
}

ul li {
list-style-type: none;
}

button {
outline: 0;
cursor: pointer;
}

a {
text-decoration: none;
color: #000;
}
/* ==== end of Base ===== */

/* ==== Navbar ==== */
.navbar {
color: #fff;
position: fixed;
top: 0;
right: 0;
z-index: 999;
}

.btn-bars {
background-color: #3c3c3c;
font-size: 1rem;
font-weight: 700;
letter-spacing: 2px;
border: none;
display: flex;
padding: 0.4rem 0.9rem;
align-items: center;
border-radius: 1rem;
margin: 1.6rem 1.6rem 0 0;
transition: all 0.4s ease;
}

.btn-bars span {
font-size: 1.6rem;
color: #fff;
}

.btn-bars:hover {
color: #fff;
scale: 1.1;
}

.navbar-collapse {
position: fixed;
top: 0;
right: 0;
height: 100%;
background-color: #272727;
/* background-color: #fff; */
width: 280px;
padding: 1.6rem;
transform: translateX(100%);
transition: all 0.5s ease;
}

.btn-close {
    background-color: #252525;
    color: #fff;
    /* color:#252525; */
    display: block;
    width: 33px;
    height: 33px;
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    margin-left: auto;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
    transition: color 0.5s ease;
}

.btn-close:hover {
    color: #fff;
    opacity: 1;
    scale: 1.1;
}

.navbar-nav {
margin: 4rem 0;
}

.nav-item {
margin: 1.4rem 0;
}

.nav-link {
color: #fff;
/* color: #272727; */
font-family: "Fira Sans", sans-serif;
font-size: 1.3rem;
transition: color 0.5s ease;
margin-left: 0.5rem;
padding: 0.5rem;
font-size: 2rem;
letter-spacing: 0.1rem;
}

.nav-link:hover {
color: #fff;
background-color: #E0872E;
text-align: left;
scale: 1.05;
border-radius: 0.5rem;
}

.nav-para {
line-height: 1.6;
font-size: 0.9rem;
opacity: 0.7;
font-weight: 300;
}

.nav-social-icon {
margin-top: 3rem;
margin-right: 4rem;
display: flex;
justify-content: space-between;
}

.nav-social-icon a {
  transform: translateY(50%);
  margin: 1.2rem;
  scale: 1.3;
  color: #E0872E;
}

.nav-social-icon a:hover{
  border: 1px solid #fff;
  border-radius: 0.5rem;
  scale: 1.4;
}

/* === show menu ===  */
.showMenu {
transform: translateX(0);
}

/* ===  main === */


.logged-user{
  font-size: 3rem;
  text-align: center;
  color: #E0872E;
  cursor: default;
}

.site-name {
  position: fixed;
  top: 1.6rem;
  left: 1.6rem;
  font-family: "Fira Sans", sans-serif;
  font-size: 3.6rem;
  font-weight: 500;
  color: #272727;
  z-index: 1;
}

.site-name:hover{
  color: #272727;
}

.site-name span {
  color: #E0872E;
}

.logo{
  scale: 2.5;
  cursor: default;
  z-index: -100;
}



@media screen and (max-width: 400px) {
  .site-name {
    font-size: 1.8rem;
  }
  .btn-bars {
    padding: 0 0.5rem;
  }
  .navbar-close {
    width: 100%;
  }
  .navbar-nav {
    margin: 2rem 0;
  }
  .header-wrapper h3 {
    font-size: 0.9rem;
    text-align: center;
  }
  .header-wrapper h1 {
    text-align: center;
    font-weight: 500;
    font-size: 1.6rem;
    width: 100%;
  }
  .header-links {
    flex-direction: column;
  }
  .header-links a {
    font-size: 0.8rem;
    margin: 0.3rem;
    text-align: center;
  }
}


@media screen and (min-width: 768px) {
  .header-social-links {
    display: block;
  }
}
@media screen and (min-width: 992px) {
  .header-wrapper {
    padding-left: 5rem;
  }
  .header-wrapper h1 {
    font-size: 3.2rem;
    width: 60%;
  }
}

