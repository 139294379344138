.bg{
    background: url('./Images/register-bg2.avif');
    height: 100vh;
    width: 100vw;
    object-fit: cover;
    background-repeat: no-repeat;
    position: relative;
    background-color: #E0872E;
    z-index: 0;
}

.login-form{
    width: 55%;
    padding: 7rem;
    border-radius: 2rem;
    position: absolute;
    top: 14%;
    right: 6%;
    background-color: #E0872E;
    border: 1px solid #fff;
    box-shadow: 1px 1px 4px 1px black;
    z-index: 2;
}


.login-button{
    background-color: transparent;
    border: 1px solid #fff;
    margin-top: 2rem;
    transform: translateX(60rem);
    font-size: 3rem;
    padding: 0.5rem;
    color: #fff;
}



.register-form input, .login-form input{
    width: 60%;
    font-size: 2rem;
}

.register-form label{
    font-size: 3rem;
    opacity: 0.8;
}

.register-form button{
    position: absolute;
    right: 3rem;
    font-size: 2rem;
    bottom: 2rem;
    background-color: transparent;
    border: 1px solid #fff;
}


.registration-title{
    position: absolute;
    top: 1rem;
    left: 1rem;
    font-size: 3rem;
    cursor: pointer;
    color: #272727;
    font-style: italic;
    opacity: 0.7;
}





.login-error-container{
    position: absolute;
    right: 0;
    top: 15rem;
    width: 38%;
    border-top: 1px solid #fff;
    color: #fff;
}

.registration-error-container p{
    font-size: 3rem;
    color: #fff;
}


.login-tab{
    width: 55%;
    height: 38%;
    padding: 4rem;
    border-radius: 2rem;
    position: absolute;
    top: 32%;
    right: 3%;
    background-color: #fff;
    box-shadow: 1px 1px 4px 1px black;
    z-index: 1;
}


.login-already{
    position: absolute;
    font-size: 2rem;
    right: 1.8rem;
    bottom: 0.3rem;
    cursor: default;
}


.register-span{
    cursor: pointer;
    color: #272727;
    opacity: 0.7;
}

.register-span a{
    font-size: 3rem;
    text-decoration: none;
    padding: 0.5rem;
}

.register-span a:hover{
    color: #E0872E;
    opacity: 1;
}