.not-found{
    margin-top: 20%;
    margin-left: 30%;
}


.recipe-card-container{
    height: 100vh;
    width: 100vw;
    position: relative;
}

.recipe-card{
    position: absolute;
    display: flex;
    top: 34rem;
    left: 8rem;
    width: 30rem;
    height: 50rem;
    flex-wrap: wrap;
}

.recipe-card h3{
    font-size: 3rem;
    margin-top: 1rem;
    font-weight: bold;
    letter-spacing: 1px;
    cursor: default;
}

.card-span{
    font-size: smaller;
    margin-left: 2rem;
    font-weight: lighter;
}


.recipe-card-bottom{
    position: absolute;
    height: 30rem;
    width: 45rem;
    top: 40%;
    cursor: default;
}

.recipe-card-bottom h1{
    font-size: 3rem;
    color: #272727;
    opacity: 1;
    letter-spacing: 1px;
    position: absolute;
    transform: translateX(-19%);
    width: 100%;
    top: -3rem;
    font-family: sans-serif;
}

.recipe-card-bottom p{
    font-style: italic;
    font-size: 3rem;
    margin-top: 4rem;
    width: 74%;
    height: 80%;
}

.recipe-card-left{
    position: absolute;
}

.left-title{
    position: absolute;
    top: 14rem;
    width: 50rem;
}

.left-title h1{
    font-size: 6rem;
    position: absolute;
    top: 0;
    left: -40rem;
}

.left-title h2{
    color: #E0872E;
    position: absolute;
    margin-left: 80%;
    margin-top: 6rem;
    font-size: 5rem;
}



.show-directions{
    position: absolute;
    width: 20rem;
    bottom: 4%;
    right: -180%;
    border: 1px solid #E0872E;
    padding: 1rem;
    border-radius: 1rem;
    cursor: pointer;
    text-align: center;
}

.show-directions:hover{
    background-color: #E0872E;
    color: black;
}



.recipe-card-right{
        background-color: #E0872E;
        width: 40%;
        height: 64rem;
        border-radius: 2rem;
        position: absolute;
        right: -2rem;
        top: -8rem;
        transform: translateY(10%);
        min-width: 31rem;
        padding: 2rem;
        z-index: 0;
}

.recipe-title{
    position: absolute;
    top: 20%;
    font-size: 4rem;
    color: #fff;
    font-family: sans-serif;
    cursor: default;
}

.recipe-title-divider{
    color: #fff;
    position: absolute;
    top: 25%;
    width: 90%;
}

.recipe-card-image{
    position: absolute;
    top: 35%;
    right: 4.5%;
    /* height: 100%;
    max-height: 38rem;
    width: 100%;
    max-width: 57rem; */
    width: 680px;
    height: 382px;
    border-radius: 2rem;
    border: 1px solid black;
}

.recipe-card-image:hover{
    scale: 1.02 ;
}


.image-container{
    display: block;
}


.directions-container{
    position: absolute;
    cursor: default;
    transform: translateX(150%);
    width: 30rem;
    height: 46rem;
}

.directions-title{
    font-size: 3rem;
    font-weight: bold;
}

.directions-text{
    font-size: 3rem;
}